<template lang="pug">
  app-dialog(title="Mark as paid" :value="showing" width="400" @click:outside="cancel" @input="cancel")
    template(#dialog-action)
      slot(name="activator" :toggle="toggle")
    template(#dialog-body)
      .school-payout__body
        app-input(
          :value="formData.amount"
          label="Amount"
          placeholder="Leave amount there"
          type="number"
          required-field
          :error="formErrors.hasError('amount')"
          :error-messages="formErrors.fields.amount"
          @input="addAmount"
        )
        app-textarea(
          :value="formData.comment"
          label="Comment"
          :maxlength="5000"
          required
          placeholder="Leave your comment there"
          :error="formErrors.hasError('comment')"
          :error-messages="formErrors.fields.comment"
          @input="addComment"
        )
    template(#dialog-footer)
      v-spacer
      app-button(color="#f4f7f9" hide-shadow @click.native="cancel").mr-3
        span.config-cancel Cancel
      app-button(color="primary" hide-shadow @click.native="submit")
        span Submit
</template>

<script>
import {DrivingSchoolService} from '../../core/drivingSchool-service'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from "@/util/form-errors"
import appButton from '@/components/global/actions/BaseButton.vue'
import appTextarea from '@/components/global/actions/BaseTextarea.vue'

export default {
  name: 'MakePayout',

  mixins: [errorsMixin, showingMixin],

  props: {
    items: {type: Array, required: true}
  },

  computed: {
    drivingSchool() {
      if (this.$route.query && this.$route.query.driving_school) return +this.$route.query.driving_school
      else return null
    }
  },

  data: () => ({
    formData: {
      amount: '',
      comment: null
    },
    formErrors: new FormErrors(),
    loading: false
  }),

  methods: {
    cancel() {
      this.updateShowing(false)
    },

    addAmount(payload) {
      this.formData.amount = payload
      this.formErrors.validField('amount')
    },

    addComment(payload) {
      this.formData.comment = payload
      this.formErrors.validField('comment')
    },

    async submit() {
      if(!this.validate(this.formErrors)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      await this.makePayout()
    },

    async makePayout() {
      try {
        this.loading = true
        let getIDList = this.items.map(item => item.id)
        let res = await new DrivingSchoolService().make_payout({
          ids: getIDList,
          driving_school: this.drivingSchool,
          amount: this.formData.amount,
          comment: this.formData.comment
        })
        this.$emit('needToUpdate', res)
        this.$notify({text: 'Payouts added', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: {
            amount: 'amount',
            comment: 'comment'
          },
        });
      } finally {
        this.loading = false
      }
    },

    resetFormData() {
      this.formData = {amount: '', comment: ''}
      this.formErrors.resetFields()
    },

    validate(validator) {
      validator.resetFields()
      this.validatePayout(this.formData, validator)
      return validator.isValid()
    },

    validatePayout(data, validator) {
      if (!data.amount) validator.invalidField('amount', 'Required.')
      if (!data.comment) validator.invalidField('comment', 'Required.')
    },
  },

  watch: {
    showing(isShowing) {
      if (!isShowing) this.resetFormData()
    }
  },

  components: {
    appButton,
    appTextarea,
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appInput: () => import('@/components/global/actions/BaseInput.vue')
  }
}
</script>

<style lang="scss">
.config-cancel {
  color: $label-color;
}

.school-payout__body {
  padding: 0 15px 10px 15px;
}
</style>
